import { combineReducers } from "redux";
import auth from "./AuthReducers";
import layout from "./LayoutReducer";
import rest from "./RestReducer";
import global from "./GlobalReducers";
import branch from "./BranchReducer";
import category from "./CategoryReducer";
import group from "./GroupReducer";
import profiles from "./ProfileReducer";
import product from "./ProductsReducer";
import tax from "./TaxReducer";
import ingredient from "./IngredientReducer";
import modifier from "./ModifiersReducer";
import modGrp from "./ModifiersGroupsReducer";
import translation from "./TranslationsReducer";
import user from "./UsersReducer";
import appUserAddresses from "./AppUserAddressesReducer";
import appUsersGroups from "./AppUsersGroupsReducer";
import allergens from "./AllergensReducer";
import role from "./RoleReducer";
import typesAndAllergies from "./TypesAndAllergiesReducer";
import menu from "./MenuReducer";
import zone from "./DeliveryZonesReducers";
import event from "./EventsReducer";
import media from "./MediaReducer";
import gallery from "./GalleryReducer";
import feedback from "./FeedbackReducers";
import promoCodes from "./PromoCodesReducer";
import modules from "./ModulesReducer";
import platform from "./PlatformReducers";
import branchesFinance from "./BranchesFinanceReducers";
import loyalty from "./LoyaltyReducer";
import discounts from "./DiscountReducers";
import order from "./OrderReducer";
import orderHistory from "./OrderHistoryReducer";
import messages from "./MessagesReducer";
import qr from "./QRReducers";
import designSetup from "./DesignSetupReducer";
import loginDesignSetup from "./LoginDesignSetupReducer";
import splashScreenDesignSetup from "./SplashScreenDesignSetupReducer";
import marketing from "./MarketingReducers";
import stockItemsReducer from "./StockItemsReducer";
import stockRecipesReducer from './StockRecipesReducer'
import productsAvailability from "./ProductsAvailabilityReducer";
import automatedMessages from "./AutomatedMessagesReducer";
import floorSetupReducer from "./FloorSetupReducer";
import notifications from "./NotificationsReducer";
import dashboard from "./DashboardReducer";
import affiliateUrl from './AffiliateUrlLinkReducer'
import email from "./EmailReducer";
import payments from "./PaymentsReducer";
import currencies from "./CurrenciesReducer";
import directMessages from "./DirectMessagesReducer";
import closingDailyReport from "./ClosingDailyReportReducer";

const reducers = combineReducers({
  auth,
  layout,
  rest,
  global,
  branch,
  category,
  group,
  profiles,
  product,
  tax,
  ingredient,
  modifier,
  modGrp,
  translation,
  user,
  appUserAddresses,
  appUsersGroups,
  allergens,
  role,
  typesAndAllergies,
  menu,
  zone,
  event,
  media,
  gallery,
  feedback,
  promoCodes,
  modules,
  platform,
  branchesFinance,
  loyalty,
  discounts,
  order,
  orderHistory,
  closingDailyReport,
  messages,
  qr,
  affiliateUrl,
  designSetup,
  loginDesignSetup,
  splashScreenDesignSetup,
  marketing,
  stockItems: stockItemsReducer,
  stockRecipes: stockRecipesReducer,
  productsAvailability,
  automatedMessages,
  floorSetupReducer,
  notifications,
  dashboard,
  payments,
  currencies,
  email,
  directMessages
});

export default reducers;
