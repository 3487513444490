import {
    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_ERROR,

    GET_DASHBOARD_TRANSACTION_REQUEST,
    GET_DASHBOARD_TRANSACTION_SUCCESS,
    GET_DASHBOARD_TRANSACTION_ERROR,

    GET_DASHBOARD_PRODUCTS_REQUEST,
    GET_DASHBOARD_PRODUCTS_SUCCESS,
    GET_DASHBOARD_PRODUCTS_ERROR,

    GET_DASHBOARD_CUSTOMERS_REQUEST,
    GET_DASHBOARD_CUSTOMERS_SUCCESS,
    GET_DASHBOARD_CUSTOMERS_ERROR,

    GET_DASHBOARD_TOTAL_REVENUE_REQUEST,
    GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
    GET_DASHBOARD_TOTAL_REVENUE_ERROR,

    GET_DASHBOARD_ORDERS_VALUE_REQUEST,
    GET_DASHBOARD_ORDERS_VALUE_SUCCESS,
    GET_DASHBOARD_ORDERS_VALUE_ERROR,

    GET_DASHBOARD_ORDERS_COUNT_REQUEST,
    GET_DASHBOARD_ORDERS_COUNT_SUCCESS,
    GET_DASHBOARD_ORDERS_COUNT_ERROR,

    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST,
    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS,
    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR,

    GET_DASHBOARD_NEW_PRODUCTS_REQUEST,
    GET_DASHBOARD_NEW_PRODUCTS_SUCCESS,
    GET_DASHBOARD_NEW_PRODUCTS_ERROR,

    CLEAR_DASHBOARD_LOADING,

    SET_DASHBOARD_REST,

    GET_CONSOLIDATED_CURRENCY_REQUEST,
    GET_CONSOLIDATED_CURRENCY_SUCCESS,
    GET_CONSOLIDATED_CURRENCY_ERROR,

    GET_RESTAURANTS_CURRENCY_REQUEST,
    GET_RESTAURANTS_CURRENCY_SUCCESS,
    GET_RESTAURANTS_CURRENCY_ERROR,

    SET_CONSOLIDATED_CURRENCY_STARTED,
    SET_CONSOLIDATED_CURRENCY_ENDED,

    SET_CARDS_POSITIONING_STARTED,
    SET_CARDS_POSITIONING_ENDED,

    GET_CARDS_POSITIONING_REQUEST,
    GET_CARDS_POSITIONING_SUCCESS,
    GET_CARDS_POSITIONING_ERROR,

    SET_PRODUCT_LAYOUT,
    SET_CUSTOMERS_LAYOUT,
    SET_TODAY_LAYOUT,
    SET_TRANSACTIONS_LAYOUT
} from '../actions/DashboardActions'

const initialState = {
    actionLoading: false,
    layoutLoading: false,
    productLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    customersLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    todayLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    transactionLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    consolidatedCurrencyLoading: false,
    restaurantsCurrencyLoading: false,
    setConsolidatedLoading: false,
    restaurantsCurrency: [],
    consolidatedCurrency: [],
    selectedRest: 'all-restaurants',
    productsLoading: false,
    loading: false,
    newProducts: [],
    totalRevenueLoading: false,
    totalRevenueLoadingTransactions: false,
    ordersValueLoading: false,
    ordersCountLoading: false,
    topSpenders: [],
    avgOrderPrice: 0,
    avgOrderPricePeriod: 0,
    lateOrders: [],
    totalOrders: null,
    newCustomers: [],
    canceledOrders: 0,
    //  totalCustomers: 0,
    salesByZone: [],
    salesByAffiliate: [],
    signupsByAffiliate: [],
    totalDiscounts: [],
    totalPromoDiscounts: [],
    topXItemsQty: [],
    topXItemsAmount: [],
    feedbacksAndComplaints: [],
    feedbacksRating: null,
    notActiveCustomers: [],
    totalRevenuePerMonth: [],
    newProduct: 0,
    leastXItemsQty: [],
    totalRevenuePerMonthTransactions: [],
    leastXItemsAmount: [],
    customerCountValue: 0,
    customersCount: 0,
    totalOrdersByYear: null,
    qrCount: 0,
    feedbacksSummaryPositive: [],
    feedbacksSummaryNegative: [],
    birthdayCount: 0,
    deliveriesStatus: null,
    notSoldItems: [],
    salesByRestaurant: [],
    salesByBranch: [],
    affiliateClicks: [],
    ordersStatus: null,
    topItemsBranch: null,
    avgOrders: 0,
    avgOrdersYear: 0,
    salesByCategory: [],
    salesByCustomer: [],
    avgDeliveryTime: 0,
    avgPrepTime: 0
}

function dashboard(state = initialState, action) {
    switch (action.type) {
        case SET_TODAY_LAYOUT:
            return Object.assign({}, state, {
                todayLayout: action.payload
            })
        case SET_PRODUCT_LAYOUT:
            return Object.assign({}, state, {
                productLayout: action.payload
            })
        case SET_CUSTOMERS_LAYOUT:
            return Object.assign({}, state, {
                customersLayout: action.payload
            })
        case SET_TRANSACTIONS_LAYOUT:
            return Object.assign({}, state, {
                transactionLayout: action.payload
            })
        case SET_CARDS_POSITIONING_STARTED:
            return Object.assign({}, state, {
                actionLoading: true
            })
        case SET_CARDS_POSITIONING_ENDED:
            return Object.assign({}, state, {
                actionLoading: false
            })
        case SET_CONSOLIDATED_CURRENCY_ENDED:
            return Object.assign({}, state, {
                setConsolidatedLoading: false
            })
        case SET_CONSOLIDATED_CURRENCY_STARTED:
            return Object.assign({}, state, {
                setConsolidatedLoading: true
            })
        case GET_RESTAURANTS_CURRENCY_ERROR:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: false,
                restaurantsCurrency: []
            })
        case GET_RESTAURANTS_CURRENCY_SUCCESS:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: false,
                restaurantsCurrency: action.payload
            })
        case GET_RESTAURANTS_CURRENCY_REQUEST:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: true
            })
        case GET_CONSOLIDATED_CURRENCY_ERROR:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: false,
                consolidatedCurrency: []
            })
        case GET_CONSOLIDATED_CURRENCY_SUCCESS:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: false,
                consolidatedCurrency: action.payload
            })
        case GET_CONSOLIDATED_CURRENCY_REQUEST:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: true
            })
        case SET_DASHBOARD_REST:
            return Object.assign({}, state, {
                selectedRest: action.payload
            })
        case GET_DASHBOARD_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                newCustomers: action.payload.newCustomers,
                feedbacksAndComplaints: action.payload.feedbacksAndComplaints,
                feedbacksRating: action.payload.feedbacksRating,
                notActiveCustomers: action.payload.notActiveCustomers,
                customerCountValue: action.payload.customerCountValue,
                customersCount: action.payload.customersCount,
                feedbacksSummaryPositive: action.payload.feedbacksSummaryPositive,
                feedbacksSummaryNegative: action.payload.feedbacksSummaryNegative,
                birthdayCount: action.payload.birthdayCount,
                avgOrders: action.payload.avgOrders,
                salesByCustomer: action.payload.salesByCustomer
            })
        case GET_DASHBOARD_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topXItemsQty: action.payload.topXItemsQty,
                topXItemsAmount: action.payload.topXItemsAmount,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonthTransactions,
                newProduct: action.payload.newProduct,
                leastXItemsQty: action.payload.leastXItemsQty,
                leastXItemsAmount: action.payload.leastXItemsAmount,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                notSoldItems: action.payload.notSoldItems,
                topItemsBranch: action.payload.topItemsBranch
            })
        case GET_DASHBOARD_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topSpenders: action.payload.topSpenders,
                avgOrderPrice: action.payload.avgOrderPrice,
                avgOrderPricePeriod: action.payload.avgOrderPricePeriod,
                lateOrders: action.payload.lateOrders,
                totalOrders: action.payload.totalOrders,
                canceledOrders: action.payload.canceledOrders,
                salesByZone: action.payload.salesByZone,
                salesByAffiliate: action.payload.salesByAffiliate,
                signupsByAffiliate: action.payload.signupsByAffiliate,
                //NEW
                totalDiscounts: action.payload.totalDiscounts,
                totalPromoDiscounts: action.payload.totalPromoDiscounts,
                topXItemsQty: action.payload.topXItemsQty,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonthTransactions,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                qrCount: action.payload.qrCount,
                deliveriesStatus: action.payload.deliveriesStatus,
                salesByRestaurant: action.payload.salesByRestaurant,
                salesByBranch: action.payload.salesByBranch,
                affiliateClicks: action.payload.affiliateClicks,
                avgOrders: action.payload.avgOrders,
                avgOrdersYear: action.payload.avgOrdersYear,
                salesByCategory: action.payload.salesByCategory,
                salesByCustomer: action.payload.salesByCustomer,
                avgDeliveryTime: action.payload.avgDeliveryTime,
                avgPrepTime: action.payload.avgPrepTime
            })
        case GET_DASHBOARD_NEW_PRODUCTS_ERROR:
            return Object.assign({}, state, {
                productsLoading: false
            })
        case GET_DASHBOARD_NEW_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                productsLoading: false,
                newProducts: action.payload
            })
        case GET_DASHBOARD_NEW_PRODUCTS_REQUEST:
            return Object.assign({}, state, {
                productsLoading: true
            })
        case GET_DASHBOARD_ORDERS_COUNT_ERROR:
            return Object.assign({}, state, {
                ordersCountLoading: false,
                customersCount: 0
            })
        case GET_DASHBOARD_ORDERS_COUNT_SUCCESS:
            return Object.assign({}, state, {
                ordersCountLoading: false,
                customersCount: action.payload
            })
        case GET_DASHBOARD_ORDERS_COUNT_REQUEST:
            return Object.assign({}, state, {
                ordersCountLoading: true
            })
        case GET_DASHBOARD_ORDERS_VALUE_ERROR:
            return Object.assign({}, state, {
                ordersValueLoading: false,
                customerCountValue: 0
            })
        case GET_DASHBOARD_ORDERS_VALUE_SUCCESS:
            return Object.assign({}, state, {
                ordersValueLoading: false,
                customerCountValue: action.payload
            })
        case GET_DASHBOARD_ORDERS_VALUE_REQUEST:
            return Object.assign({}, state, {
                ordersValueLoading: true
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: false,
                totalRevenuePerMonthTransactions: []
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: false,
                totalRevenuePerMonthTransactions: action.payload
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: true
            })
        case GET_DASHBOARD_TOTAL_REVENUE_ERROR:
            return Object.assign({}, state, {
                totalRevenueLoading: false,
                totalRevenuePerMonth: []
            })
        case GET_DASHBOARD_TOTAL_REVENUE_SUCCESS:
            return Object.assign({}, state, {
                totalRevenueLoading: false,
                totalRevenuePerMonth: action.payload
            })
        case GET_DASHBOARD_TOTAL_REVENUE_REQUEST:
            return Object.assign({}, state, {
                totalRevenueLoading: true
            })
        case CLEAR_DASHBOARD_LOADING:
            return Object.assign({}, state, {
                loading: false,
            })
        case GET_DASHBOARD_REQUEST:
        case GET_DASHBOARD_TRANSACTION_REQUEST:
        case GET_DASHBOARD_PRODUCTS_REQUEST:
        case GET_DASHBOARD_CUSTOMERS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topSpenders: action.payload.topSpenders,
                avgOrderPrice: action.payload.avgOrderPrice,
                lateOrders: action.payload.lateOrders,
                totalOrders: action.payload.totalOrders,
                newCustomers: action.payload.newCustomers,
                canceledOrders: action.payload.canceledOrders,
                // totalCustomers: action.payload.totalCustomers,
                salesByZone: action.payload.salesByZone,
                salesByAffiliate: action.payload.salesByAffiliate,
                signupsByAffiliate: action.payload.signupsByAffiliate,
                totalDiscounts: action.payload.totalDiscounts,
                totalPromoDiscounts: action.payload.totalPromoDiscounts,
                topXItemsQty: action.payload.topXItemsQty,
                topXItemsAmount: action.payload.topXItemsAmount,
                feedbacksAndComplaints: action.payload.feedbacksAndComplaints,
                feedbacksRating: action.payload.feedbacksRating,
                notActiveCustomers: action.payload.notActiveCustomers,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                newProduct: action.payload.newProduct,
                leastXItemsQty: action.payload.leastXItemsQty,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonth,
                leastXItemsAmount: action.payload.leastXItemsAmount,
                avgOrderPricePeriod: action.payload.avgOrderPricePeriod,
                customerCountValue: action.payload.customerCountValue,
                customersCount: action.payload.customersCount,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                qrCount: action.payload.qrCount,
                feedbacksSummaryPositive: action.payload.feedbacksSummaryPositive,
                feedbacksSummaryNegative: action.payload.feedbacksSummaryNegative,
                birthdayCount: action.payload.birthdayCount,
                deliveriesStatus: action.payload.deliveriesStatus,
                notSoldItems: action.payload.notSoldItems,
                ordersStatus: action.payload.ordersStatus,
                avgDeliveryTime: action.payload.avgDeliveryTime,
                avgPrepTime: action.payload.avgPrepTime
            })
        case GET_DASHBOARD_ERROR:
        case GET_DASHBOARD_TRANSACTION_ERROR:
        case GET_DASHBOARD_PRODUCTS_ERROR:
        case GET_DASHBOARD_CUSTOMERS_ERROR:
            return initialState
        default:
            return state
    }
}
export default dashboard