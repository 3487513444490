import { handleError } from '../../utils/utils'

export const SET_TOTAL_SALES_CHECKED = 'SET_TOTAL_SALES_CHECKED';
export const SET_DISCOUNT_CHECKED = 'SET_DISCOUNT_CHECKED';
export const SET_TAXES_CHECKED = 'SET_TAXES_CHECKED';
export const SET_NET_SALES_CHECKED = 'SET_NET_SALES_CHECKED';
export const SET_SALES_BY_ITEMS_CHECKED = 'SET_SALES_BY_ITEMS_CHECKED';
export const SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED = 'SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED';
export const SET_SALES_BY_WORKSTATION_CHECKED = 'SET_SALES_BY_WORKSTATION_CHECKED';
export const SET_SALES_BY_CATEGORIES_CHECKED = 'SET_SALES_BY_CATEGORIES_CHECKED';
export const SET_SALES_BY_GROUP_CHECKED = 'SET_SALES_BY_GROUP_CHECKED';
export const SET_SUMMARY_OF_PAYMENTS_CHECKED = 'SET_SUMMARY_OF_PAYMENTS_CHECKED';
export const SET_FIRST_ORDER_CHECKED = 'SET_FIRST_ORDER_CHECKED';
export const SET_LAST_ORDER_CHECKED = 'SET_LAST_ORDER_CHECKED';
export const SET_FILTER = 'SET_FILTER';

const actions = {
//SET TOTAL SALES CHECKED
    setTotalSalesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TOTAL_SALES_CHECKED,
      payload: {
        value
      }
    })
  },
//SET DISCOUNT CHECKED
    setDiscountChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_DISCOUNT_CHECKED,
      payload: {
        value
      }
    })
  },
//SET TOTAL SALES CHECKED
    setTaxesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_TAXES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET NET SALES CHECKED
    setNetSalesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_NET_SALES_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY ITEMS CHECKED
  setSalesByItemsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_ITEMS_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES SUMMARY BY EMPLOYEE CHECKED
  setSalesSummaryByEmployeeChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED,
      payload: {
        value
      }
    })
  },
  //SET SALES BY WORKSTATION CHECKED
  setSalesByWorkstationChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_WORKSTATION_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET SALES BY CATEGORIES CHECKED
 setSalesByCategoriesChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_CATEGORIES_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET SALES BY GROUP CHECKED
 setSalesByGroupChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SALES_BY_GROUP_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET SUMMARY OF PAYMENTS CHECKED
 setSummaryOfPaymentsChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_SUMMARY_OF_PAYMENTS_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET FIRST ORDER CHECKED
 setFirstOrderChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_FIRST_ORDER_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET LAST ORDER CHECKED
 setLastOrderChecked: (value) => (dispatch) => {
    dispatch({
      type: SET_LAST_ORDER_CHECKED,
      payload: {
        value
      }
    })
  },
 //SET FILTER
 setFilter: (value) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    payload: {
      value
    }
  })
},
}

export default actions
