import {
   SET_TOTAL_SALES_CHECKED,
   SET_DISCOUNT_CHECKED,
   SET_TAXES_CHECKED,
   SET_NET_SALES_CHECKED,
   SET_SALES_BY_ITEMS_CHECKED,
   SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED,
   SET_SALES_BY_WORKSTATION_CHECKED,
   SET_SALES_BY_CATEGORIES_CHECKED,
   SET_SALES_BY_GROUP_CHECKED,
   SET_SUMMARY_OF_PAYMENTS_CHECKED,
   SET_FIRST_ORDER_CHECKED,
   SET_LAST_ORDER_CHECKED,
   SET_FILTER
    
  } from '../actions/ClosingDailyReportActions'
  
  const initialState = {
    totalSalesChecked: true,
    discountChecked: true,
    taxesChecked: true,
    netSalesChecked: true,
    salesByItemsChecked: true,
    salesSummaryByEmployeeChecked: true,
    salesByWorkstationChecked: true,
    salesByCategoriesChecked: true,
    salesByGroupChecked: true,
    summaryOfPaymentsChecked: true,
    firstOrderChecked: true,
    lastOrderChecked: true,
    filter : {
      eodRange: { 
      value: "last-7-days", 
      from: null,
      to: null 
    }
    }
  }
  
  function closingDailyReport(state = initialState, action) {
    switch (action.type) {
      case SET_TOTAL_SALES_CHECKED:
        return Object.assign({}, state, {
          totalSalesChecked: action.payload.value
        })
      case SET_DISCOUNT_CHECKED:
        return Object.assign({}, state, {
          discountChecked: action.payload.value
        })
      case SET_TAXES_CHECKED:
        return Object.assign({}, state, {
          taxesChecked: action.payload.value
        })
      case SET_NET_SALES_CHECKED:
        return Object.assign({}, state, {
          netSalesChecked: action.payload.value
        })
      case SET_SALES_BY_ITEMS_CHECKED:
        return Object.assign({}, state, {
          salesByItemsChecked: action.payload.value
        })
      case SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED:
        return Object.assign({}, state, {
         salesSummaryByEmployeeChecked: action.payload.value
        })
      case SET_SALES_BY_WORKSTATION_CHECKED:
        return Object.assign({}, state, {
         salesByWorkstationChecked: action.payload.value
        })
      case SET_SALES_BY_CATEGORIES_CHECKED:
        return Object.assign({}, state, {
          salesByCategoriesChecked: action.payload.value
        })
      case SET_SALES_BY_GROUP_CHECKED:
        return Object.assign({}, state, {
          salesByGroupChecked: action.payload.value
        })
      case SET_SUMMARY_OF_PAYMENTS_CHECKED:
        return Object.assign({}, state, {
          summaryOfPaymentsChecked: action.payload.value
        })
      case SET_FIRST_ORDER_CHECKED:
        return Object.assign({}, state, {
          firstOrderChecked: action.payload.value
        })
      case SET_LAST_ORDER_CHECKED:
        return Object.assign({}, state, {
          lastOrderChecked: action.payload.value
        })
      case SET_FILTER:
         return Object.assign({}, state, {
            filter: action.payload.value
          })
      default:
        return state
    }
  }
  
  export default closingDailyReport
  